import React from 'react';

import Section from '../section';
import SummaryItem from '../summary-item';

const SectionOldProjects = ({ oldprojects }) => {
  if (!oldprojects.length) return null;

  return (
    <Section title="Past Projects">
      {oldprojects.map((oldproject) => (
        <SummaryItem
          key={oldproject.name}
          name={oldproject.name}
          description={oldproject.description}
          link={oldproject.link}
        />
      ))}
    </Section>
  );
};

export default SectionOldProjects;
